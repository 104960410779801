/*
function add(a, b) {
    return a + b;
}

module.exports = add;
*/
let scrollani = false;

window.onload = () => {
  const breite = document.documentElement.clientWidth
  const hoehe = document.documentElement.clientHeight
  const logo = document.getElementById('logo')
  const warbe = document.getElementById('warbe')
  const glas = document.getElementById('glas')
  const biene = document.getElementById('biene')
  const name = document.getElementById('name')
  const glas_img = glas.children[0]
  const glas_img_mt = glas_img.offsetTop;
  const glaslimit = hoehe - glas_img.clientHeight




  window.addEventListener('scroll', function (e) {
    window.requestAnimationFrame(function () {
      //doSomething(window.scrollY);
      let scroll_pos = window.scrollY;
      let bienenversatz = scroll_pos * 2
      let logo_pos = scroll_pos * .5
      logo.style.top = logo_pos + "px"
      let logo_opacity = 0
      let logo_opacity_tmp = 0
      if (hoehe < breite) logo_opacity_tmp = hoehe - window.scrollY - 100
      else logo_opacity_tmp = (hoehe / 2) - window.scrollY - 100
      if (logo_opacity_tmp > 200) { logo_opacity = 1 }
      else if ((logo_opacity_tmp > 0)) { logo_opacity = logo_opacity_tmp / 200 }
      logo.style.opacity = logo_opacity

      if (logo_opacity === 0) {
        let teiler = 600
        if (hoehe > breite) teiler = 300
        let name_opacity_tmp = 0 - (logo_opacity_tmp / teiler)
        name.style.opacity = name_opacity_tmp
      }
      else {
        name.style.opacity = 0

        if (breite > (breite / 100 * 72) + bienenversatz) {
          biene.style.top = (0 - scroll_pos * 1.25) + "px"
          biene.style.marginLeft = bienenversatz + "px"
          biene.style.display = ''
        }
        else {
          biene.style.display = 'none'
          biene.style.marginLeft = "0px"

        }

      }
      let glas_pos_top = 0
      let glas_animatin_start = 200
      if (hoehe > breite) glas_animatin_start = 80

      if (logo_pos > glas_animatin_start) {
        glas_pos_top = logo_pos + ((scroll_pos - (glas_animatin_start * 2)) * 1.6)
        let glas_pos_aktuell = glas_pos_top - scroll_pos + glas_img_mt

        if (glaslimit < glas_pos_aktuell) {

          glas_pos_top = glas_pos_top + (glaslimit - glas_pos_aktuell)
        }

      }
      else {
        glas_pos_top = logo_pos
      }
      if ((scroll_pos + (glas_img.clientHeight / 2)) < (document.getElementById('impressum').offsetHeight + document.getElementById('impressum').offsetTop - hoehe)) glas.style.top = glas_pos_top + "px"

    });
  });
}